<template>
  <div class="hovering__pad py-3">
    <h2 class="mx-4 mb-4 mt-2">История платежей</h2>
    <b-table
      responsive
      stacked="md"
      :tbody-tr-class="rowClass"
      v-bind="{ ...$props, ...$attrs }"
      :fields="fields"
      bordered
      striped
    >
      <template #cell(assignment)>Подписка Easyprbot</template>
      <template #cell(amount)="data">{{ Math.round(data.item.amount / 100) }} ₽</template>
      <template #cell(created)="data">{{
        new Date(data.item.created) | moment('DD.MM.YYYY')
      }}</template>
    </b-table>
    <div class="d-flex justify-content-center py-3 pt-4">
      <b-button
        @click="
          () => {
            showMore = !showMore;
            $emit('show-more', showMore);
          }
        "
        class="mx-auto"
        variant="link"
        >{{ showMore ? 'Свернуть историю платежей' : 'Показать всю историю платежей' }}</b-button
      >
    </div>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';

const defField = {
  class: isMobile ? '' : 'py-3 px-3',
  thClass: 'fw-500',
};

export default {
  data: () => ({
    showMore: false,
  }),
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      return item.status_simplified;
    },
  },
  computed: {
    fields() {
      return [
        {
          key: 'created',
          label: 'Дата',
          ...defField,
        },
        {
          key: 'assignment',
          label: isMobile ? 'Назначение' : 'Назначение платежа',
          ...defField,
        },
        {
          key: 'status',
          label: isMobile ? 'Статус' : 'Статус платежа',
          ...defField,
          class: `${defField.class} status-pct-cell`,
          formatter(value, key, item) {
            if (item.message && item.status_simplified === 'danger') {
              return `${value}: ${item.message}`;
            }
            return value;
          },
        },
        {
          key: 'amount',
          label: 'Сумма',
          ...defField,
          formatter(value, key, item) {
            const am = Number(value);
            if (am >= 1200000) {
              return am / 10000;
            }
            if (am >= 100000) {
              return am / 100;
            }
            return am;
          },
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.status-pct-cell {
  min-width: 200px;
}
.danger {
  .status-pct-cell {
    color: #ff0000;
  }
}
.accepted {
  .status-pct-cell {
    color: #00d463;
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 768px) {
  ::v-deep .table.b-table.b-table-stacked-md > tbody > tr > ::before {
    text-align: left;
    color: #6a7b83;
    width: 32%;
    font-weight: normal !important;
  }
  ::v-deep .table.b-table.b-table-stacked-md > tbody > tr > td > div {
    width: calc(100% - 32%);
    text-align: right !important;
  }
  ::v-deep .table.b-table.b-table-stacked-md > tbody > tr > td {
    border: none !important;
  }
  ::v-deep .table.b-table.b-table-stacked-md > tbody > tr:not(:first-child) {
    border-top: 1px solid #d5dfe4;
  }
}
</style>
