<template>
  <div
    class="p-3 d-flex align-items-center"
    style="background-color: #fffce3; border-radius: 10px; border: 1px solid #d5dfe4"
  >
    <img
      class="mr-2"
      style="width: 60px; height: 60px"
      src="@main/assets/img/energy-icons/1_4x.png"
      alt=""
    />
    <h5 style="font-size: 20px" class="">
      Останьтесь с нами и получите <span class="fw-500">30</span> ⚡
      <span class="fw-500">проверок блогеров</span> через
      {{ currentUser.subscription_days_left }}
      {{ literal.declension(currentUser.subscription_days_left, 'дн') }}
    </h5>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['currentUser']),
  },
};
</script>
