<template>
  <div>
    <p
      v-for="(adv, idx) in advantages"
      :class="{ 'mb-2': idx < advantages.length - 1 }"
      :key="`${idx}-KnndAdbvgD`"
      class="d-flex"
    >
      <img class="mr-2" src="@main/assets/img/svg/subscription_icons/green_icon.svg" alt="" />
      {{ adv }}
    </p>
  </div>
</template>

<script>
export default {
  data: () => ({
    advantages: [
      'Cтоимость подписки 2 990 ₽ в месяц',
      'Автопродление подписки каждые 30 дней',
      'При оплате подписки 10 проверок блогеров ⚡ в подарок',
    ],
  }),
};
</script>
