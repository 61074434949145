<template>
  <div class="mb-4">
    <h1 class="my-4">Управление подпиской</h1>
    <div class="d-lg-flex flex-md-row justify-content-start align-items-start">
      <div class="mr-lg-5">
        <div v-if="subscriptionState.disabledByUser" class="hovering__pad p-3 subblck mb-4">
          <h2>Автопродление приостановлено</h2>
          <p>
            Вы можете пользоваться всеми сервисами Easyprbot до
            {{ new Date(currentUser.subscription_end_date) | moment('DD.MM.YY') }}, включительно.
            После подписка перестанет работать. <br />
            <br />
            Деньги больше не будут списываться с карты.
          </p>
        </div>
        <div v-else-if="subscriptionState.newSub" class="hovering__pad p-3 subblck mb-4">
          <h2>Автоподписка не активна</h2>
          <p>
            <br />
            Вы можете пользоваться всеми сервисами Easyprbot до
            {{ new Date(currentUser.subscription_end_date) | moment('DD.MM.YY') }}, включительно.
            После подписка перестанет работать. <br />
            <br />
            После окончания подписки, деньги не будут списаны с карты.
          </p>
        </div>

        <div
          class="hovering__pad subblck p-3 mb-4 mb-lg-0"
          :style="{
            border: subscriptionState.active
              ? '1px solid #00d463'
              : subscriptionState.error
              ? '1px solid red'
              : '',
          }"
          style="max-width: 345px"
        >
          <div v-if="subscriptionState.new || !currentUser">
            <h2 class="mt-1">Оформить подписку Easyprbot</h2>
            <p class="mt-3">Оформите подписку и пользуйтесь всеми сервисами Easyprbot.</p>
            <AdvantageBlock class="mt-3" />
            <b-button
              @click="
                () => {
                  $v.$touch();
                  !$v.$anyError ? charge() : null;
                }
              "
              class="w-100 mt-4"
              variant="yellow"
              >Оформить подписку</b-button
            >
            <b-checkbox
              v-model="policyAgree"
              class="mt-4"
              @change="
                () => {
                  $v.$touch();
                }
              "
              :class="{ error: $v.policyAgree.$anyError }"
              style="font-size: 14px !important"
              size="lg"
              ><span class="mt-1 d-block"
                >Я принимаю
                <a target="_blank" href="https://easyprbot.com/offero/">условия оферты</a></span
              >
            </b-checkbox>
            <!--b-button
              href="https://sub.easyprbot.com/"
              target="_blank"
              class="w-100 mt-4"
              variant="outline-default"
              >Подробнее о подписке</!--b-button
            -->
            <p class="gray-text fs-12 mt-4" style="line-height: 13.04px">
              Подписка продлевается автоматически, если автопродление не отключено за 24 часа до
              окончания оплаченного периода. При отмене автопродления ранее оплаченные дни подписки
              сохраняются до конца оплаченного периода.
              <br />
              <br />
              Вы можете управлять подпиской или отключить автопродление на странице Управление
              подпиской на сайте Easyprbot.
              <br />
              <br />
              Почта Службы заботы о клиентах —
              <b-button class="d-inline-block fs-12" variant="link" href="mailto:help@easyprbot.com"
                >help@easyprbot.com</b-button
              >
            </p>
          </div>
          <div v-else-if="subscriptionState.newSub">
            <h2 class="mt-1">Оформить автопродление Easyprbot</h2>
            <p class="mt-3">Оформите автопродление и пользуйтесь всеми сервисами Easyprbot.</p>
            <AdvantageBlock class="mt-3" />
            <b-button
              @click="
                () => {
                  $v.$touch();
                  !$v.$anyError ? charge() : null;
                }
              "
              class="w-100 mt-4"
              variant="yellow"
              >Включить автопродление</b-button
            >
            <b-checkbox
              @change="
                () => {
                  $v.$touch();
                }
              "
              v-model="policyAgree"
              class="mt-4"
              :class="{ error: $v.policyAgree.$anyError }"
              style="font-size: 14px !important"
              size="lg"
              ><span class="mt-1 d-block"
                >Я принимаю
                <a target="_blank" href="https://easyprbot.com/offero/">условия оферты</a></span
              >
            </b-checkbox>
            <p class="gray-text fs-12 mt-4" style="line-height: 13.04px">
              Подписка продлевается автоматически, если автопродление не отключено за 24 часа до
              окончания оплаченного периода. При отмене автопродления ранее оплаченные дни подписки
              сохраняются до конца оплаченного периода.
              <br />
              <br />
              Вы можете управлять подпиской или отключить автопродление на странице Управление
              подпиской на сайте Easyprbot.
              <br />
              <br />
              Почта Службы заботы о клиентах —
              <b-button class="d-inline-block fs-12" variant="link" href="mailto:help@easyprbot.com"
                >help@easyprbot.com</b-button
              >
            </p>
          </div>
          <div v-else-if="subscriptionState.active">
            <h2 class="mt-1" style="color: #00d463">Автопродление активно</h2>
            <p v-if="currentUser.subscription_days_left" class="mt-3">
              Осталось {{ currentUser.subscription_days_left }}
              {{ literal.declension(currentUser.subscription_days_left, 'дн') }} подписки
            </p>
            <p v-else class="mt-3">Подписка закончится {{ subHoursLeft }}</p>
            <p class="mt-2">
              Следующее списание произойдет
              {{
                new Date() | moment('add', `${currentUser.subscription_days_left} days`, 'DD.MM.YY')
              }}
            </p>
            <b-button @click="autopayToggle(false)" class="mt-3 w-100" variant="outline-default"
              >Отменить автопродление</b-button
            >
            <p style="line-height: 14.22px" class="gray-text fs-12 text-center mt-2">
              Вы можете отменить автопродление сейчас и продолжить пользоваться сервисами до
              {{
                new Date() | moment('add', `${currentUser.subscription_days_left} days`, 'DD.MM.YY')
              }}
            </p>
            <b-button @click="initRecurrent" class="mt-3" variant="link"
              >Изменить карту списания за 1 рубль</b-button
            >
            <br />
            <p class="mt-3 gray-text fs-12">
              Почта Службы заботы о клиентах —
              <b-button class="fs-12" variant="link" href="mailto:help@easyprbot.com"
                >help@easyprbot.com</b-button
              >
            </p>
          </div>
          <div v-else-if="subscriptionState.disabledByUser">
            <h2 class="mt-1">Возобновить автопродление</h2>
            <AdvantageBlock class="mt-3" />
            <b-button class="mt-3 w-100" @click="autopayToggle(true)" variant="yellow"
              >Возобновить автопродление</b-button
            >
            <p class="gray-text fs-12 mt-4" style="line-height: 13.04px">
              Подписка продлевается автоматически, если автопродление не отключено за 24 часа до
              окончания оплаченного периода. При отмене автопродления ранее оплаченные дни подписки
              сохраняются до конца оплаченного периода.
              <br />
              <br />
              Вы можете управлять подпиской или отключить автопродление на странице Управление
              подпиской на сайте Easyprbot.
              <br />
              <br />
              Почта Службы заботы о клиентах —
              <b-button class="d-inline-block fs-12" variant="link" href="mailto:help@easyprbot.com"
                >help@easyprbot.com</b-button
              >
            </p>
          </div>
          <div v-else-if="subscriptionState.ended">
            <h2 v-if="currentUser.subscription_end_date" class="mt-1">
              Подписка закончилась
              {{ new Date(currentUser.subscription_end_date) | moment('DD.MM.YY') }}
            </h2>
            <h2 v-else class="mt-1">Подписка закончилась</h2>
            <p class="mt-2">
              Вы можете возобновить подписку и пользоваться всеми сервисами Easyprbot.
            </p>
            <AdvantageBlock class="mt-3" />
            <b-button @click="reCharge" class="w-100 mt-3" variant="yellow"
              >Возобновить подписку</b-button
            >
            <b-button
              class="w-100 mt-3"
              href="https://sub.easyprbot.com/"
              target="_blank"
              variant="outline-default"
              >Подробнее о подписке</b-button
            >
            <p class="gray-text fs-12 mt-4 mb-2" style="line-height: 13.04px">
              Подписка продлевается автоматически, если автопродление не отключено за 24 часа до
              окончания оплаченного периода. При отмене автопродления ранее оплаченные дни подписки
              сохраняются до конца оплаченного периода.
              <br />
              <br />
              Вы можете управлять подпиской или отключить автопродление на странице Управление
              подпиской на сайте Easyprbot.
              <br />
              <br />
              Почта Службы заботы о клиентах —
              <b-button class="d-inline-block fs-12" variant="link" href="mailto:help@easyprbot.com"
                >help@easyprbot.com</b-button
              >
            </p>
          </div>
          <div v-else-if="subscriptionState.error">
            <h2 class="mt-1" style="color: red">Подписка неактивна</h2>
            <p class="mt-2">
              При продлении вашей подписки возникла проблема. Мы попытались удержать 1 990 ₽ с вашей
              банковской карты, но не смогли этого сделать.<br /><br />
              Возможно на карте:<br />– Недостаточно средств<br />– Ошибка списания<br />– Запрет на
              операции в интернете
            </p>
            <b-button class="mt-3 w-100" @click="charge" variant="yellow"
              >Повторить попытку списания с текущей карты</b-button
            >
            <b-button class="mt-3 w-100" variant="yellow" @click="paymentRedirect"
              >Оплатить с другой карты</b-button
            >
            <b-button @click="autopayToggle(false)" class="mt-3 w-100" variant="outline-default"
              >Отменить автопродление</b-button
            >
            <p class="gray-text fs-12 mt-4 mb-2" style="line-height: 13.04px">
              Вы можете продлить подписку с любой другой карты. После успешной оплаты новая карта
              будет использоваться для автоматического продления подписки. Вы можете изменить карту
              списания или отключить автопродление на странице Управление подпиской на сайте
              Easyprbot.
            </p>
          </div>
          <div v-else-if="subscriptionState.intermediaryError">
            <h2 class="mt-1" style="color: red">Подписка неактивна</h2>
            <p class="mt-2">
              При продлении вашей подписки возникла проблема. Мы попытались удержать 1 990 ₽ с вашей
              банковской карты, но не смогли этого сделать.
            </p>
            <p class="mt-2">
              Следующая попытка списания средств произойдет в интервале от 1 до 3 следующих дней
            </p>
            <b-button variant="outline-default" @click="toggleSubscribe(false)" class="mt-3 w-100"
              >Отменить списание</b-button
            >
            <b-button class="mt-3 w-100" @click="charge" variant="yellow"
              >Повторить попытку с текущей картой</b-button
            >
            <b-button class="mt-3 w-100" variant="yellow" @click="paymentRedirect"
              >Оплатить с другой карты</b-button
            >
            <p class="gray-text fs-12 mt-4 mb-2" style="line-height: 13.04px">
              Вы можете продлить подписку с любой другой карты. После успешной оплаты новая карта
              будет использоваться для автоматического продления подписки. Вы можете изменить карту
              списания или отключить автопродление на странице Управление подпиской на сайте
              Easyprbot.
            </p>
          </div>
          <div v-else-if="subscriptionState.waiting">
            <h2 class="mt-1">
              Подключение подписки...
              {{ new Date(currentUser.subscription_end_date) | moment('DD.MM.YY') }}
            </h2>
            <p class="mt-2">
              Идет подключение подписки, обычно это длится не более пяти минут! Средства будут
              списаны с ранее подключеной карты. Если данное состяние длится более пяти минут,
              обратитесь в техническую поддержку
            </p>
            <Loader class="mx-auto" />
            <b-button
              class="w-100 mt-3"
              href="https://sub.easyprbot.com/"
              target="_blank"
              variant="outline-default"
              >Что входит в подписку</b-button
            >
            <b-button @click="toggleSubscribe(false)" class="w-100 mt-3" variant="outline-default"
              >Отменить списание</b-button
            >
            <p class="gray-text fs-12 mt-4 mb-2" style="line-height: 13.04px">
              Подписка продлевается автоматически, если автопродление не отключено за 24 часа до
              окончания оплаченного периода. При отмене автопродления ранее оплаченные дни подписки
              сохраняются до конца оплаченного периода.
              <br />
              <br />
              Вы можете управлять подпиской или отключить автопродление на странице Управление
              подпиской на сайте Easyprbot.
              <br />
              <br />
              Почта Службы заботы о клиентах —
              <b-button class="d-inline-block fs-12" variant="link" href="mailto:help@easyprbot.com"
                >help@easyprbot.com</b-button
              >
            </p>
          </div>
        </div>
      </div>
      <PaymentTable
        v-if="pays"
        @show-more="
          (val) => {
            if (val) params.page_size = 100;
            else params.page_size = 4;
            updateData();
          }
        "
        :items="pays"
      />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import paymentsActions from '@main/func/contrib/payments';
import apiService from '@main/api/index';
import payments from '@main/api/payments';
import auth from '@main/api/users';
import wrappers from '@main/func/wrappers';
import { RequiresAuth } from '@main/func/pipeline';
import moment from 'moment';
import Loader from '@main/components/Loader.vue';
import PaymentTable from '@main/components/payments/PaymentTable.vue';
import AdvantageBlock from './AdvantageBlock.vue';
import AdvantageWindow from './AdvantageWindow.vue';
import SubscriptionStopConfirm from './SubscriptionStopConfirm.vue';

export default {
  components: {
    AdvantageBlock,
    PaymentTable,
    Loader,
  },
  data: () => ({
    policyAgree: false,
    pays: null,
    params: {
      page_size: 4,
    },
    dataInterval: null,
  }),
  validations: {
    policyAgree: {
      policyAgree(val) {
        return val;
      },
    },
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapGetters(['subscriptionState']),
    subHoursLeft() {
      if (!this.currentUser.subscription_end_date) {
        return;
      }
      moment.locale('ru');
      return moment().to(moment(this.currentUser.subscription_end_date));
    },
  },
  methods: {
    ...mapActions(['loadUser']),
    ...mapMutations('notifications', ['clearWindows']),
    ...mapActions('notifications', ['setWindow']),
    async updateData() {
      const paymentsList = await payments.sub.payments.list(this.params);
      this.pays = paymentsList.results.map((val) => ({
        ...val,
        _rowVariant: val.status_simplified,
      }));
    },
    async toggleSubscribe(val) {
      if (this.currentUser.profile.subscription_member) {
        await auth.toggleSubscribe(val);
        await this.loadUser();
      }
    },
    async toggleSubscribeWindow(val) {
      await this.toggleSubscribe(val);
      this.setWindow({
        title: 'Автопродление приостановлено',
        html: `Вы можете пользоваться всеми сервисами Easyprbot до ${moment()
          .add(this.currentUser.subscription_days_left, 'd')
          .format(
            'DD.MM.YY'
          )}, включительно. После подписка перестанет работать. <br><br>Деньги больше не будут списываться с карты.`,
        buttonClass: 'm-0',
        footer: {
          attrs: {
            class: 'd-flex justify-content-between w-100 mt-3',
          },
        },
        buttons: [
          {
            text: 'Хорошо',
            attrs: { variant: 'yellow' },
            handler: (button, idx) => {
              this.clearWindows();
            },
          },
        ],
      });
    },
    async autopayToggle(val) {
      if (!val) {
        this.setWindow({
          contentClass: 'overflow-auto p-3 p-md-4',
          contentComponent: { component: AdvantageWindow },
          buttonClass: 'mt-3 w-100 w-sm-auto',
          footer: {
            attrs: {
              class: 'd-flex justify-content-between w-100 mt-3',
            },
          },
          buttons: [
            {
              text: 'Оставить автопродление',
              attrs: { variant: 'yellow' },
              handler: (button, idx) => {
                this.setWindow(null);
              },
            },
            {
              text: 'Отменить автопродление',
              attrs: { variant: 'outline-default' },
              handler: async (btn, idxx) => {
                try {
                  const saveSubscriptionPromo = await payments.promo.saveSubscriptionPromo.check();
                  if (saveSubscriptionPromo.available) {
                    this.setWindow({
                      title: 'Вы уверены, что хотите отказаться от подписки?',
                      contentComponent: { component: SubscriptionStopConfirm },
                      topLess: false,
                      buttons: [
                        {
                          text: 'Отключить автопродление',
                          attrs: { variant: 'outline-default' },
                          handler: () => {
                            this.toggleSubscribeWindow(val);
                          },
                        },
                        {
                          text: 'Оставить подписку',
                          attrs: { variant: 'yellow' },
                          handler: (button, idx) => {
                            this.setWindow(null);
                            payments.promo.saveSubscriptionPromo.apply();
                          },
                        },
                      ],
                    });
                    return;
                  }
                  await this.toggleSubscribeWindow(val);
                  return;
                } catch (e) {
                  console.log(e);
                }
              },
            },
          ],
        });
      } else {
        await this.toggleSubscribe(val);
      }
    },
    async paymentRedirect() {
      const res = await payments.sub.createPayment();
      console.log(res);
      window.location.href = res[0].PaymentURL;
    },
    async charge() {
      const handler = new RequiresAuth();
      handler.handle(
        () => {
          if (this.currentUser.profile.subscription_member) {
            try {
              payments.sub.charge().then(() => {
                this.updateData();
              });
            } catch (e) {
              const { detail } = e.response.data;
              this.showMessage({
                // eslint-disable-next-line prefer-template
                title: `Не удалось активировать подписку` + detail ? ` по причине: ${detail}` : '',
                defaultMessage: 'error',
              });
            }
            this.loadUser();
            return;
          }
          this.setWindow({
            title: 'Оформление подписки Easyprbot',
            html: `После оплаты вам будет начислена подписка на следующие 30 дней с момента завершения транзакции, по окончании 30-ти дневного срока подписки и в дальнейшем, плата за подписку будет автоматически списываться каждые 30 дней.

Вы можете отменить автоматическое списание денег за следующие периоды на странице управление подпиской.

При отмене автоматического списания действие подписки Easyprbot сохраняется до конца оплаченного периода`,
            buttons: [
              {
                text: 'Назад',
                attrs: { variant: 'outline-default' },
                handler: () => {
                  this.setWindow(null);
                },
              },
              {
                text: 'Оформить подписку',
                attrs: { variant: 'yellow' },
                handler: async () => {
                  await this.paymentRedirect();
                  this.setWindow(null);
                },
              },
            ],
          });
        },
        {
          callback: () => {
            this.paymentRedirect();
          },
        }
      );
    },
    async reCharge() {
      this.setWindow({
        title: 'Возобновление подписки',
        html: `Плата за следующие 30 дней пользования сервисом будет списана прямо сейчас, а в дальнейшем будет автоматически списываться каждые 30 дней.

Вы можете отменить автоматическое списание денег за следующий период на странице управление подпиской.`,
        buttons: [
          {
            text: 'Назад',
            attrs: { variant: 'outline-default' },
            handler: () => {
              this.setWindow(null);
            },
          },
          {
            text: 'Возобновить подписку',
            attrs: { variant: 'yellow' },
            handler: async () => {
              await this.charge();
              this.setWindow(null);
            },
          },
        ],
      });
    },
    async initRecurrent() {
      await paymentsActions.recurrentInitPaymentRedirect();
    },
  },
  async mounted() {
    try {
      await this.updateData();
    } catch (e) {
      console.log(e);
    }
    this.dataInterval = setInterval(async () => {
      await this.updateData();
      await this.loadUser();
    }, 5000);
  },
  destroyed() {
    clearInterval(this.dataInterval);
  },
};
</script>

<style lang="scss" scoped>
.subblck {
  max-width: 345px;
}
@media (max-width: 999px) {
  .subblck {
    width: 100% !important;
    max-width: 100% !important;
  }
}
::v-deep .custom-control.custom-checkbox.error {
  border: none !important;
  label::before {
    border: 1px solid red;
  }
}
</style>
